<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="sendAction('DETALLES')">
        <feather-icon icon="AlignJustifyIcon" class="mr-50" />
        <span class="align-middle">Detalles</span>
      </b-button>
    </b-button-group>

  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    AgGridTable,
    BrunaElementLoader
  },

  directives: {
  },

  data: function ()
  {
    return {
      cellValue: null,





    };
  },
  beforeMount()
  {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);




  },
  methods: {
    sendAction(action_name, data, params)
    {
      this.params.context.componentParent.sendAction(action_name, this.params.data, this.params)
    },
    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },


    getValueToDisplay(params)
    {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>